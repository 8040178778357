import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Switch from 'react-switch';
import { MdPhotoCamera, MdCloudDone } from 'react-icons/md';
import { toast } from 'react-toastify';
import { differenceInCalendarMonths, format } from 'date-fns';
import { FaSpinner } from 'react-icons/fa';
import dateUTC from 'utils/dateUTC';
import ContextColorHeader from 'utils/contexts/ColorHeaderContext';

import {
  Display,
  Container,
  CreateDreamCard,
  Form,
  DivSwitch,
  DivDropzone,
  DivButtons,
} from './styles';
import goal_icon from 'assets/goal_icon.svg';
import Card from 'components/Cards';
import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
import CurrencyInput from 'components/masks/CurrencyInput';
import Loading from 'components/Loading';
import Can from 'components/Can';
import UpdateGoal from './components/Modals/UpdateGoal';
import api from 'services/api';
import newApi from 'services/newApi';
import InfoCard from 'components/Cards/Info';
import { BsFillHouseFill } from 'react-icons/bs';
import { AiOutlineStock, AiFillWarning } from 'react-icons/ai';
import FreedomFinancial from './components/Cards/freedomFinancial';
import { goalsAllocationDefault } from 'utils/defaultsDropDown';
import Dropdown from 'components/Input/Dropdown';
import DefaultGoal from './components/Cards/default';
import { liquidityOptions } from 'utils/defaultsDropDown';
import { useHistory } from 'react-router-dom';
export default function Goals() {
  // Dados do formulário
  const [name, setName] = useState('');
  const [description, setDescription] = useState(''); // O que significa pra você?
  const [value, setValue] = useState(''); // Valor do sonho
  const [profitability, setProfitability] = useState(0.7); // Rentabilidade estimada
  const [end, setEnd] = useState(format(new Date(), 'yyyy-MM-dd')); // Prazo
  const [savedMoney, setSavedMoney] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // Dado que foi selecionado para ser excluido
  const [selectedData, setSelectedData] = useState({});
  // Goals que serão carregados da api
  const [goals, setGoals] = useState([]);
  // Armazena o goal selecionado para fazer update dos dados
  const [selectedGoal, setSelectedGoal] = useState({});
  // Controla o switch
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  // importar acceptedFiles
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const { id } = useSelector(state => state.user.profile);
  const theme = useContext(ThemeContext);
  const { setColorHeader } = useContext(ContextColorHeader);
  const [financialBalance, setFinancialBalance] = useState(0);
  const [patrimonialBalance, setPatrimonialBalance] = useState(0);
  const [thumbnail] = useState();
  const [allocationStrategy, setallocationStrategy] = useState();
  const [liquidity, setLiquidity] = useState();
  const [patrimonialActiveValue, setPatrimonialActiveValue] = useState();
  const [financialActiveValue, setFinancialActiveValue] = useState(0);
  const history = useHistory();
  setColorHeader('investments');
  async function fetchBalance() {
    const { data: balance } = await api.get(`goals/${id}/seals/balance`);
    setFinancialBalance(balance.financial);
    setPatrimonialBalance(balance.patrimonial);

    setLoading(false);
  }
  useEffect(() => {
    async function fetchedGoals() {
      setLoading(true);
      const { data } = await api.get(`/users/${id}/goals`, {
        params: {
          all: true,
        },
      });

      setGoals(data);
    }

    fetchedGoals();
    fetchBalance();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setGoals, id]);

  /**
   * Calcula o investimento mensal
   */
  useEffect(() => {
    setSavedMoney(financialActiveValue + patrimonialActiveValue);
  }, [patrimonialActiveValue, financialActiveValue]);

  function calcIFP() {
    // prazo é sub de end até new Date;
    // const pv é o que já possui
    // const i é taxa
    // const FV valor do sonho
    const isFirstDayOfMonth = end.split('-')[2] === '01';
    const subtractValue = isFirstDayOfMonth ? 0 : 1;
    const differenceInMonths =
      differenceInCalendarMonths(new Date(end), new Date()) - subtractValue + 1;
    const n = differenceInMonths <= 0 ? 1 : differenceInMonths;
    const i = profitability / 100;
    const fv = value - patrimonialActiveValue;
    const pv = financialActiveValue;
    const ifp = (fv - pv * (1 + i) ** n) * (((1 + i) ** n - 1) / i) ** -1;

    return (ifp || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  function handleSavedMoney(savedMoney, totalValue, withCurrency) {
    let percent = (savedMoney / totalValue) * 100;

    if (isNaN(percent)) {
      percent = 0;
    }

    if (percent >= 100) {
      percent = 100;
    }

    if (withCurrency) {
      percent = percent.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    return percent;
  }

  async function handleDeleteGoal(goal_id) {
    try {
      setLoading(true);
      await api.delete(`/goals/${goal_id}`);
      await fetchBalance();

      setGoals(goals.filter(goal => goal.id !== goal_id));
      setLoading(false);
      toast.success('Excluido com sucesso');
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao excluir');
    }
    setShowUpdateModal(false);
  }

  async function handleDoneGoal(goal) {
    try {
      setLoading(true);

      const { done_at, id } = goal;

      let done;
      if (done_at) done = null;
      if (!done_at) done = new Date();

      const { data } = await api.put(`/goals/${id}`, {
        done_at: done,
      });

      setGoals(
        goals.map(goal => {
          if (goal.id !== id) return goal;
          return data;
        })
      );
      await fetchBalance();

      setLoading(false);
      toast.success('Concluído');
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao tentar concluir');
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    if (!acceptedFiles[0]) {
      return toast.warn('Selecione uma imagem para o projeto');
    }

    if (!name || !description || !end) {
      return toast.warn(
        'É Obrigatório preenchear o nome, o que significa pra você e a data'
      );
    }
    const day = end.substring(8, 10);
    if (day <= new Date().getDate()) {
      return toast.warn(
        'A data de conclusão deve ser maior que a data de atual'
      );
    }
    setLoading(true);

    const file = await api.post('/files', formData);
    try {
      let response;
      if (toggleSwitch) {
        response = await api.post(`/users/${id}/goals`, {
          name,
          description,
          end: dateUTC(end),
          file_id: file.data.id,
          saved_money: savedMoney,
        });
        console.log('dentro do if', response);
      } else {
        response = await api.post(`/users/${id}/goals`, {
          name,
          description,
          value,
          profitability,
          end: dateUTC(end),
          file_id: file.data.id,
          saved_money: savedMoney,
          strategic_allocation: allocationStrategy,
          patrimonial_active_value: patrimonialActiveValue,
          financial_active_value: financialActiveValue,
          liquidity,
        });
        console.log('dentro do else', response);
      }

      setGoals([...goals, response.data]);

      setName('');
      setDescription('');
      setValue(0);
      setProfitability(0);
      setEnd(format(new Date(), 'yyyy-MM-dd'));
      setSavedMoney(0);
      acceptedFiles.length = 0;
      fetchBalance();
      setLoading(false);
      setallocationStrategy('');
      setPatrimonialActiveValue('');
      setFinancialActiveValue('');

      setShowModal(!showModal);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  }

  return (
    <Display>
      <div className="header">
        <InfoCard
          title="Patrimônio Financeiro"
          CardIcon={financialBalance < 0 ? AiFillWarning : AiOutlineStock}
          shake={financialBalance < 0}
          color={financialBalance < 0 ? 'debts' : 'receipts'}
          onClick={() => history.push('/finances/patrimony')}
          mainInfo={(financialBalance || 0).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          subtitle={'Disponível'}
        />

        <InfoCard
          title="Patrimônio Material"
          className="card-balance"
          shake={patrimonialBalance < 0}
          CardIcon={patrimonialBalance < 0 ? AiFillWarning : BsFillHouseFill}
          color={patrimonialBalance < 0 ? 'debts' : 'investments'}
          onClick={() => history.push('/finances/patrimony')}
          mainInfo={(patrimonialBalance || 0).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          subtitle={'Disponível'}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          {goals.map((goal, index) =>
            goal?.type === 'financial-independence' ? (
              <FreedomFinancial
                key={`goal-${index}`}
                handleSavedMoney={handleSavedMoney}
                showModal={showModal}
                goal={goal}
                setShowUpdateModal={setShowUpdateModal}
                setSelectedGoal={setSelectedGoal}
                handleDoneGoal={handleDoneGoal}
              />
            ) : (
              <DefaultGoal
                key={`goal-default-${index}`}
                handleSavedMoney={handleSavedMoney}
                setSelectedData={setSelectedData}
                goal={goal}
                setShowDeleteModal={setShowDeleteModal}
                setShowUpdateModal={setShowUpdateModal}
                setSelectedGoal={setSelectedGoal}
                setShowModal={setShowModal}
                handleDoneGoal={handleDoneGoal}
              />
            )
          )}
          <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
            <Card width="100%" height="340px">
              <CreateDreamCard>
                <div className="icon" onClick={() => setShowModal(!showModal)}>
                  <img src={goal_icon} alt="Goal Icon" />
                  <span>
                    Aqui você realiza <br /> <span>seus sonhos!</span>
                  </span>
                </div>

                <div className="add-goal">
                  <button onClick={() => setShowModal(!showModal)}>
                    Adicionar novo sonho
                  </button>
                </div>
              </CreateDreamCard>
            </Card>
          </Can>
        </Container>
      )}
      {showModal && (
        <Modal
          color="primary"
          title="Novo Sonho"
          setShowModal={setShowModal}
          icon={<MdCloudDone />}
          width={toggleSwitch ? '400px' : '800px'}
        >
          <Form onSubmit={e => handleSubmit(e)}>
            <div className="left">
              <Input
                label="Nome:"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <Input
                label="O que significa pra você?"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <DivDropzone thumbnail={thumbnail}>
                <div {...getRootProps({ className: 'dropzone' })}>
                  {acceptedFiles.length > 0 ? (
                    <h3>{acceptedFiles[0].name}</h3>
                  ) : (
                    <MdPhotoCamera />
                  )}
                  <input {...getInputProps()} />
                </div>
              </DivDropzone>

              <div className="inputGroup">
                <Input
                  label="Prazo:"
                  width="160px"
                  value={end}
                  type="date"
                  onChange={e => setEnd(e.target.value)}
                />
                <DivSwitch>
                  <label>Sem valor definido</label>
                  <Switch
                    onChange={() => {
                      setToggleSwitch(!toggleSwitch);
                      setValue(0);
                      setProfitability(0);
                      setSavedMoney(0);
                    }}
                    checked={toggleSwitch}
                    offColor={theme.grafit.default}
                    onColor={theme.info.default}
                    onHandleColor={theme.info.hover}
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={16}
                    width={45}
                  />
                </DivSwitch>
              </div>
              {!toggleSwitch && (
                <CurrencyInput
                  label="Valor total"
                  prefix="R$ "
                  value={value}
                  onChange={e => setValue(e)}
                />
              )}

              {toggleSwitch && (
                <div className="buttonGroup">
                  <Button onClick={e => handleSubmit(e)}>
                    {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
                  </Button>
                </div>
              )}
            </div>
            {/* feito */}
            {!toggleSwitch && (
              <div className="right">
                <div className="inputGroup">
                  <CurrencyInput
                    label="Patrimônio Imobilizado atual:"
                    value={patrimonialActiveValue}
                    onChange={setPatrimonialActiveValue}
                  />
                  <CurrencyInput
                    label="Patrimônio Financeiro atual:"
                    value={financialActiveValue}
                    onChange={setFinancialActiveValue}
                  />
                </div>
                <Input
                  style={{ background: '#e9e9e9' }}
                  label="Valor total atual:"
                  disabled
                  value={(savedMoney || 0).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                />

                <div className="inputGroup drop">
                  <Dropdown
                    label="Estratégia de alocação:"
                    options={goalsAllocationDefault}
                    defaultValue={{
                      value: allocationStrategy,
                      label: allocationStrategy,
                    }}
                    onChange={selected => setallocationStrategy(selected.value)}
                  />
                  <Dropdown
                    label="Liquidez:"
                    options={liquidityOptions}
                    defaultValue={{
                      value: liquidity,
                      label: liquidity,
                    }}
                    onChange={selected => setLiquidity(selected.value)}
                  />
                </div>

                <CurrencyInput
                  suffix="% a.m."
                  label="Rentabilidade:"
                  value={profitability}
                  onChange={e => setProfitability(e)}
                />

                <Input
                  style={{ background: '#e9e9e9' }}
                  label="Investimento Mensal:"
                  disabled
                  value={calcIFP()}
                />
                <div className="buttonGroup">
                  <Button onClick={e => handleSubmit(e)}>
                    {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </Modal>
      )}
      {showUpdateModal && (
        <UpdateGoal
          fetchBalance={fetchBalance}
          selectedGoal={selectedGoal}
          goals={goals}
          setGoals={setGoals}
          setShowModal={setShowModal}
          showModal={showModal}
          setShowUpdateModal={setShowUpdateModal}
          patrimonialBalance={patrimonialBalance}
          financialBalance={financialBalance}
        />
      )}
      {showDeleteModal && (
        <Modal
          color="danger"
          setShowModal={setShowDeleteModal}
          title="Confirmar Exclusão? "
          icon={null}
        >
          <DivButtons>
            <Button
              color="danger"
              onClick={() => {
                handleDeleteGoal(selectedData);
                setShowDeleteModal(!showDeleteModal);
              }}
            >
              {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
            </Button>
          </DivButtons>
        </Modal>
      )}
    </Display>
  );
}
